import React from "react"
import { Link } from "rebass"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"

const DownloadButton = ({ file }) => {
  return (
    <Link href={file} download color="gray" mx={0}>
      <FontAwesomeIcon icon={faDownload} size="m"></FontAwesomeIcon>
    </Link>
  )
}

export default DownloadButton
