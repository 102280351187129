import React from "react"
import { useThemeUI } from "theme-ui"
import { Box, Flex, Heading, Text } from "rebass"

const Typography = () => {
  const { theme } = useThemeUI()

  return (
    <>
      <Heading
        as="h3"
        mt={10}
        fontSize={2}
        fontWeight="500"
        sx={{ textTransform: "uppercase" }}
      >
        Typography
      </Heading>

      <Heading as="h4" fontSize={2} fontWeight="400" mt={2} mb={5}>
        Main font
      </Heading>

      {[
        {
          name: "Euclid Circular A - Light",
          weight: "300",
          style: "normal",
        },
        {
          name: "Euclid Circular A - Regular",
          weight: "400",
          style: "normal",
        },
        {
          name: "Euclid Circular A - Medium",
          weight: "500",
          style: "normal",
        },
        {
          name: "Euclid Circular A - Semibold",
          weight: "600",
          style: "normal",
        },
        {
          name: "Euclid Circular A - Bold",
          weight: "700",
          style: "normal",
        },
      ].map(font => (
        <Box key={font.weight}>
          <Text fontWeight="500" fontSize={1} mt={3}>
            {font.name}
          </Text>

          <Flex flexDirection="row" alignItems="center">
            <Box pr={7}>
              <Text fontWeight={font.weight} fontSize={5}>
                Aa
              </Text>
            </Box>

            <Box sx={{ fontWeight: "300", flexGrow: 1 }}>
              <Text
                sx={{
                  textTransform: "uppercase",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                abcdefghijklmnopqrstuvwxyz
              </Text>
              <Text sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                abcdefghijklmnopqrstuvwxyz
              </Text>
              <Text
                sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
              >{`1234567890(,.:;?!$&*)`}</Text>
            </Box>

            <Box
              sx={{
                pl: [4],
                borderLeft: `1px solid ${theme.colors.lightgray}`,
              }}
            >
              <Text>
                <Text as="span" color="gray">
                  Weight:
                </Text>{" "}
                {font.weight}
              </Text>

              <Text>
                <Text as="span" color="gray">
                  Style:
                </Text>{" "}
                {font.style}
              </Text>
            </Box>
          </Flex>
        </Box>
      ))}
    </>
  )
}

export default Typography
