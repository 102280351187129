import React from "react"
import { Box, Flex, Text } from "rebass"
import Layout from "../../components/_layout"
import Container from "../../components/_layout/container"
import SectionHeader from "../../components/_layout/section-header"
import FeatureHeading from "../../components/heading"
import Divider from "../../components/divider"
import Logo from "./components/logo"
import Typography from "./components/typography"
import Timeline from "./components/timeline"
import Pattern from "../../components/pattern"
import PressIcon from "./components/icon"
import PressIconPayoff from "./components/icon-payoff"
import SEO from "../../components/seo"
import { css } from "emotion"

const PressKit = () => {
  return (
    <Layout>
      <SEO
        title="Press kit"
        description="All there is to know about Spinup, from the beginning to the current day"
      />
      <SectionHeader title="Press kit" />
      <Pattern opacity={0.2} />
      <Box bg="dark" color="white" mb={8}>
        <Divider
          theme="dark"
          firstLine="Hi. Nice to meet you: we are Spinup! We are the first Italian media tech company and we are an organization where marketing and tech bear the same importance."
          secondLine="Find out more about Spinup"
        />
      </Box>

      <Container>
        <FeatureHeading
          py={[0]}
          subtitle="About us"
          title={
            <>
              <span>All</span> there is to know about Spinup
            </>
          }
        />
      </Container>

      <Container py={0} pt={[7, 5]} pb={0}>
        <Flex flexDirection={["column", "row"]} justifyContent="center">
          <Flex
            textAlign="center"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
            p={5}
          >
            <img
              src="https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/madcon.png"
              alt=""
              className={css`
                max-width: 200px;
              `}
            />
            <Text
              mt={3}
              sx={{
                maxWidth: "350px",
                textAlign: "center",
              }}
            >
              Spinup is among the 100 best companies worldwide for marketing and
              advertising according to MADcon Dubai
            </Text>
          </Flex>
          <Flex
            textAlign="center"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
            p={5}
          >
            <img
              src="https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/lefonti_2020.png"
              alt=""
              className={css`
                max-width: 200px;
              `}
            />
            <Text
              mt={3}
              sx={{
                maxWidth: "350px",
                textAlign: "center",
              }}
            >
              {
                "“Le Fonti Awards 2020”: Excellence of the Year Innovation & Performance Marketing"
              }
            </Text>
          </Flex>
          <Flex
            textAlign="center"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
            p={5}
          >
            <img
              src="https://i.imgur.com/0KhzgsK.png"
              alt=""
              className={css`
                max-width: 100px;
              `}
            />
            <Text
              mt={3}
              sx={{
                maxWidth: "350px",
                textAlign: "center",
              }}
            >
              Spinup is the 8th company in Italy for growth in the rankings of
              Il Sole 24 Ore and Statista
            </Text>
          </Flex>
        </Flex>
      </Container>

      <Container size="small" py={0} pt={0} pb={[3, 8]}>
        <Timeline />
        <Logo />
        <PressIcon />
        <PressIconPayoff />
        <Typography />
      </Container>
    </Layout>
  )
}

export default PressKit
