import React from "react"
import { useThemeUI } from "theme-ui"
import { Box, Flex, Heading, Image, Text } from "rebass"
import LogoBlack from "../../../../assets/images/logo-black.svg"
import LogoWhite from "../../../../assets/images/logo-white.svg"
import LogoBlackColor from "../../../../assets/images/logo-black-color.svg"
import LogoWhiteColor from "../../../../assets/images/logo-white-color.svg"
import DownloadButton from "../../../../components/download-button"

const Logo = () => {
  const { theme } = useThemeUI()

  return (
    <>
      <Heading
        mt={10}
        as="h3"
        fontSize={2}
        fontWeight="500"
        sx={{ textTransform: "uppercase" }}
      >
        Spinup
      </Heading>

      <Heading as="h4" fontSize={2} fontWeight="400" mt={2}>
        Logo
      </Heading>

      <Text fontSize={1} mt={3}>
        Digital use | RGB
      </Text>

      <Flex width="100%" mt={5} flexDirection={["column", "row"]}>
        <Box
          sx={{
            width: ["100%", "50%"],
            mr: [0, 4],
          }}
        >
          <Flex
            sx={{
              width: "100%",
              height: 300,
              alignItems: "center",
              justifyContent: "center",
              border: `1px solid ${theme.colors.lightgray}`,
            }}
          >
            <Image src={LogoBlackColor} width="50%" />
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Text mt={3} fontWeight="500" fontSize={1}>
                Logo
              </Text>

              <Text mt={0} fontWeight="400" fontSize={0} color="gray">
                Standard
              </Text>
            </Box>

            <DownloadButton file={LogoBlackColor} />
          </Flex>
        </Box>

        <Box
          sx={{
            width: ["100%", "50%"],
            ml: [0, 4],
            mt: [5, 0],
          }}
        >
          <Flex
            bg="secondary"
            sx={{
              width: "100%",
              height: 300,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image src={LogoWhiteColor} width="50%" />
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Text mt={3} fontWeight="500" fontSize={1}>
                Logo
              </Text>

              <Text mt={0} fontWeight="400" fontSize={0} color="gray">
                Alternate
              </Text>
            </Box>
            <DownloadButton file={LogoWhiteColor} />
          </Flex>
        </Box>
      </Flex>

      <Flex width="100%" mt={5} flexDirection={["column", "row"]}>
        <Box
          sx={{
            width: ["100%", "50%"],
            mr: [0, 4],
          }}
        >
          <Flex
            bg="dark"
            sx={{
              width: "100%",
              height: 300,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image src={LogoWhite} width="50%" />
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Text mt={3} fontWeight="500" fontSize={1}>
                Logo
              </Text>

              <Text mt={0} fontWeight="400" fontSize={0} color="gray">
                Black and white
              </Text>
            </Box>

            <DownloadButton file={LogoWhite} />
          </Flex>
        </Box>

        <Box
          sx={{
            width: ["100%", "50%"],
            ml: [0, 4],
            mt: [5, 0],
          }}
        >
          <Flex
            sx={{
              width: "100%",
              height: 300,
              alignItems: "center",
              justifyContent: "center",
              border: `1px solid ${theme.colors.lightgray}`,
            }}
          >
            <Image src={LogoBlack} width="50%" />
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Text mt={3} fontWeight="500" fontSize={1}>
                Logo
              </Text>

              <Text mt={0} fontWeight="400" fontSize={0} color="gray">
                Black and white - Alternate
              </Text>
            </Box>

            <DownloadButton file={LogoBlack} />
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

export default Logo
