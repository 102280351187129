import React from "react"
import { useThemeUI } from "theme-ui"
import { Box, Flex, Heading, Image, Text } from "rebass"
import IconGrayBlack from "../../../../assets/images/logo-icon-gray-black.svg"
import IconGrayWhite from "../../../../assets/images/logo-icon-gray-white.svg"
import IconPurpleBlack from "../../../../assets/images/logo-icon-black.svg"
import IconPurpleWhite from "../../../../assets/images/logo-icon-white.svg"
import DownloadButton from "../../../../components/download-button"

const PressIcon = () => {
  const { theme } = useThemeUI()

  return (
    <>
      <Heading as="h4" fontSize={2} fontWeight="400" mt={7}>
        Brand mark
      </Heading>

      <Text fontSize={1} mt={3}>
        Digital use | RGB
      </Text>

      <Flex width="100%" mt={5} flexDirection={["column", "row"]}>
        <Box
          sx={{
            width: ["100%", "50%"],
            mr: [0, 4],
          }}
        >
          <Flex
            sx={{
              width: "100%",
              height: 300,
              alignItems: "center",
              justifyContent: "center",
              border: `1px solid ${theme.colors.lightgray}`,
            }}
          >
            <Image src={IconPurpleBlack} width="25%" />
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Text mt={3} fontWeight="500" fontSize={1}>
                Brand mark
              </Text>

              <Text mt={0} fontWeight="400" fontSize={0} color="gray">
                Standard
              </Text>
            </Box>

            <DownloadButton file={IconPurpleBlack} />
          </Flex>
        </Box>

        <Box
          sx={{
            width: ["100%", "50%"],
            ml: [0, 4],
            mt: [5, 0],
          }}
        >
          <Flex
            bg="secondary"
            sx={{
              width: "100%",
              height: 300,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image src={IconPurpleWhite} width="25%" />
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Text mt={3} fontWeight="500" fontSize={1}>
                Brand mark
              </Text>

              <Text mt={0} fontWeight="400" fontSize={0} color="gray">
                Alternate
              </Text>
            </Box>
            <DownloadButton file={IconPurpleWhite} />
          </Flex>
        </Box>
      </Flex>

      <Flex width="100%" mt={5} flexDirection={["column", "row"]}>
        <Box
          sx={{
            width: ["100%", "50%"],
            mr: [0, 4],
            mt: [5, 0],
          }}
        >
          <Flex
            bg="dark"
            sx={{
              width: "100%",
              height: 300,
              alignItems: "center",
              justifyContent: "center",
              border: `1px solid ${theme.colors.lightgray}`,
            }}
          >
            <Image src={IconGrayWhite} width="25%" />
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Text mt={3} fontWeight="500" fontSize={1}>
                Brand mark
              </Text>

              <Text mt={0} fontWeight="400" fontSize={0} color="gray">
                Gray and White
              </Text>
            </Box>

            <DownloadButton file={IconGrayWhite} />
          </Flex>
        </Box>

        <Box
          sx={{
            ml: [0, 4],
            width: ["100%", "50%"],
          }}
        >
          <Flex
            bg="background"
            sx={{
              width: "100%",
              height: 300,
              alignItems: "center",
              justifyContent: "center",
              border: `1px solid ${theme.colors.lightgray}`,
            }}
          >
            <Image src={IconGrayBlack} width="25%" />
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Text mt={3} fontWeight="500" fontSize={1}>
                Brand mark
              </Text>

              <Text mt={0} fontWeight="400" fontSize={0} color="gray">
                Gray and black
              </Text>
            </Box>

            <DownloadButton file={IconGrayBlack} />
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

export default PressIcon
